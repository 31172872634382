import * as React from 'react'
import * as pageTitleStyle from '../styles/pageTitle.module.scss'



const PageTitle = ({ pageTitle, children }) => {
    
    return (
      <div className={pageTitleStyle.pageTitle}>
            <div className={pageTitleStyle.pageTitleBg}>
                <div>
                    <h1>{pageTitle}</h1>
                </div>
            </div>
        </div>
    )
  }
  
  export default PageTitle