import * as React from 'react'
import NavHeader from '../../components/navHeaderEn'
import SiteFooter from '../../components/footerEn'
import PageTitle from '../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
          <div className="egovernment-container">
            <section className={eGovernmentStyle.egDescription}>
              <div className="section-container">
              <h1>Portfolio of solutions within eGovernment</h1>
              <p>
              Globatel, JSC specializes in the production of complex electronical communication solutions, including both electronic safety and system integration. It participates in the delivery of solutions to  Central portal of public administration and its common modules, replacement of paper stamps by electronic ones and of systems for an open communication with the public.
              </p>
              </div>
            </section>
            <section className={eGovernmentStyle.egButtons}>
              <div className="section-container">
                <Link to="/en/eGovernment/egovernment-trustworthy-delivery">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.deliveryIcon}></div>
                    <span>System for trustworthy delivery</span>
                  </div>
                </Link>
                <Link to="/en/eGovernment/egovernment-payment-system">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.paymentIcon}></div>
                    <span>Unified payment system</span>
                  </div>
                </Link>
                <Link to="/en/eGovernment/egovernment-public-grants">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.grantIcon}></div>
                    <span>Public grants</span>
                  </div>
                </Link>
              </div>
            </section>
          </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment