// extracted by mini-css-extract-plugin
export var egovSection = "eGovernment-module--egovSection--22Dcn";
export var articleText = "eGovernment-module--articleText--21XKw";
export var egButton = "eGovernment-module--egButton--oDcKP";
export var egButtons = "eGovernment-module--egButtons--3LyER";
export var egDescription = "eGovernment-module--egDescription--3ZGOc";
export var egButtonIcon = "eGovernment-module--egButtonIcon--2E9EV";
export var deliveryIcon = "eGovernment-module--deliveryIcon--3ft40";
export var paymentIcon = "eGovernment-module--paymentIcon--2yzAH";
export var grantIcon = "eGovernment-module--grantIcon--1tSZW";
export var articleHeading = "eGovernment-module--articleHeading--1os3Z";